* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.navWrapper {
  height: 46px;
}

.ui.menu.nav {
  margin-bottom: 0;
}

.ui.menu .item.titleWrapper {
  flex: 1;
  font-size: 20px;
}

.contentWrapper {
  flex: 1;
}

.Viewer {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.viewerWrapper {
  flex: 5;
  /* position: relative; */
}

#root {
  height: 100vh;
}

[data-amplify-authenticator] {
  min-height: 100vh;
}

.commentsBar {
  flex: 1;
}

.commentsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid lightgray;
}

.commentsHeader {
  padding: 15px;
}

.commentsList {
  overflow: auto;
  flex: 1;
  padding: 0 15px 15px 15px;
}

.replyForm {
  margin-bottom: 30px;
}

.commentField {
  margin-bottom: 1em;
}

.ui.comments .commentWrapper {
  margin: 15px 0;
}

.ui.comments .commentWrapper:first-child {
  margin-top: 0;
}

.commentAuthor {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.comments .comment .metadata {
  margin-left: 0;
}

.ui.comments .comment .actions a {
  text-decoration: underline;
}

.ui.comments .reply.form textarea {
  height: 90px;
}

/* Uploads */

.uploadWrapper {
  margin-top: 30px;
}

.labelText {
  padding-right: 15px;
}
